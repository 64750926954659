import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlbaranesEnviar } from '../interfaces/albaranesEnviar.interface';
import { FacturaCompra } from '../interfaces/facturaCompra.interface';
import { Vencimiento } from '../interfaces/vencimiento.interface';

const base_url = `${ environment.base_url }/FacturasCompra`;

@Injectable({
  providedIn: 'root'
})
export class FacturasCompraService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  facturarAlbaranes(data:AlbaranesEnviar[]){
    const url = `${ base_url }/facturarAlbaranes`;
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          
                          const respuesta = {status:resp.status, detalle:resp.detalle, id:resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllFacturasCompra(pagina:number =1, rows:number = 15, desdeNumero:string, hastaNumero: string = environment.marcaFinal, desdeCodigoProveedor:string = '', hastaCodigoProveedor:string = environment.marcaFinal, desdeNombreFiscal:string = '', hastaNombreFiscal:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, orden:string = 'albaran', desc:number = 0, lote: string = '', pendientes: number = 0, desdeFacturaProveedor: string = '', hastaFacturaProveedor: string = environment.marcaFinal, desdeFechaVto: Date |string = '1980-01-01', hastaFechaVto: Date | string = '2999-12-31'){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&denumc=${desdeNumero}&hanumc=${hastaNumero
                  }&depro=${desdeCodigoProveedor}&hapro=${hastaCodigoProveedor}&defisc=${desdeNombreFiscal
                  }&defecvto=${desdeFechaVto}&hafecvto=${hastaFechaVto
                  }&defacpro=${desdeFacturaProveedor}&hafacpro=${hastaFacturaProveedor
                  }&hafisc=${hastaNombreFiscal}&defecc=${desdeFecha}&hafecc=${hastaFecha}&orentc=${orden}&desc=${desc}&lotel=${lote}&pend=${pendientes}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevaFacturaCompra(data:FacturaCompra){
    const url = `${ base_url }/postFacturaCompra`;

    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarFacturaCompra(data:FacturaCompra){
    const url = `${ base_url }/putFacturaCompra/${data.id!}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturaCompraNumero(numeroFactura: number, fechaFactura: Date){
    const url = `${ base_url }/getFacturaCompraNumero/${numeroFactura}/${fechaFactura}`;
    // console.log('Llamo a: ', url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturaCompraId(id:number){
    const url = `${ base_url }/getFacturaCompraId/${id}`;
    // console.log('Llamo a: ', url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarFacturaCompra(id:number){
    const url = `${ base_url }/borrarFacturaCompra/${id}`;
    // console.log('Llamo a: ', url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturaProveedorNumero(numero: string, fecha:Date, idProveedor: number){
    const url = `${ base_url }/getFacturaProveedorNumero/${numero}/${fecha}/${idProveedor}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVencimientoIdFacturaCompra(idFactura: number){
    const url = `${ base_url }/getVencimientoIdFactura/${idFactura}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  cambiarFormaPago(idFactura:number, idFormaPago: number, descripcionFormaPago: string){
    const url = `${ base_url }/cambiarFormaPago/${idFactura}/${idFormaPago}/${descripcionFormaPago}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  grabarVencimientos(datos:Vencimiento[], idFactura:number){
    const url = `${ base_url }/postVencimientos/${idFactura}`;
    const data = {vencimientos: datos};
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVencimientosIdsFacturas(data: number[]){
    const url = `${ base_url }/getVencimientosIdsFacturas`;
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getExportacionFacturasGestoria(data: number[]){
    const url = `${ base_url }/getExportacionFacturasGestoria`;
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  
}
