import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Fichada } from '../interfaces/fichada.interface';

const base_url = `${ environment.base_url }/Fichadas`;

@Injectable({
  providedIn: 'root'
})
export class FichadasService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  nueva(data:Fichada){
    const url = `${ base_url }/postFichada`;
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllTrabajadores(orden:string){
    const url = `${ base_url }/getAllTrabajadores?&order=${orden}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:Fichada){
    const url = `${ base_url }/putFichada/${data.id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  fichadaManual(data:Fichada){
    
    const url = `${ base_url }/putFichadaManual/${data.id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAll(pagina:number =1, rows:number = 15, desdeUsuario:number = 0, hastaUsuario:number = 9999999999, desdeFecha: Date, hastaFecha: Date, orden:string = 'fecha', desc:number = 0){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&deusu=${desdeUsuario}&hausu=${hastaUsuario}&defec=${desdeFecha}&hafec=${hastaFecha}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFichadaId(id:number){
    const url = `${ base_url }/getFichadaId/${id}`;
    // console.log(url);
    
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllReport(desdeUsuario:number = 0, hastaUsuario:number = 9999999999, desdeFecha: Date, hastaFecha: Date, orden:string = 'fecha', desc:number = 0){
    const url = `${ base_url }/getAll?deusu=${desdeUsuario}&hausu=${hastaUsuario}&defec=${desdeFecha}&hafec=${hastaFecha}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  
}
