import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Articulo } from '../interfaces/articulo.interface';
import { forkJoin, Observable, of, throwError } from 'rxjs';

const base_url = `${environment.base_url}/Woocommerce`;

@Injectable({
   providedIn: 'root'
})
export class WoocommerceService {

   token: string = localStorage.getItem('tp-Token') || '';
   headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Tp-Token': localStorage.getItem('tp-Token') || ''
   });


   constructor(private http: HttpClient) { }

   //   getArticulosWoocommerce(urlTienda: string = 'https://distribucionespenas.com/wp-json/wc/v3/products', consumerKey: string = 'ck_9ec0a86f58e69efa3d850a246cc30bafef30bef4', consumerSecret: string = 'cs_bd7987298e16582c7e9a4e49878def2d2bce58bf'){
   getArticulosWoocommerce(urlTienda: string, consumerKey: string, consumerSecret: string) {

      const url = urlTienda;
      const auth = btoa(`${consumerKey}:${consumerSecret}`);
      const perPage = 100;
      const headers = new HttpHeaders({
         Authorization: `Basic ${auth}`,
      });
      // Solicita la primera página para obtener el total de productos
      return this.http
         .get<any[]>(`${url}?per_page=${perPage}`, {
            headers,
            observe: 'response',
         })
         .pipe(
            switchMap((response) => {
               const totalPages = Number(response.headers.get('x-wp-totalpages'));
               // Para hacer las pruebas solo voy a utilizar 1 página.
               // const totalPages = 1;
               // Genera un array de observables para cada página adicional
               console.log(totalPages);
               
               const requests: Observable<any[]>[] = [];
               for (let page = 2; page <= totalPages; page++) {
                  requests.push(
                     this.http.get<any[]>(
                        `${url}?per_page=${perPage}&page=${page}`,
                        { headers }
                     )
                  );
               }

               // Une todos los resultados de las páginas
               return forkJoin([of(response.body), ...requests]).pipe(
                  map((pages) =>
                     pages.reduce((acc: any[], curr) => (acc || []).concat(curr || []), [])
                  )
               );
            })
         );
   }

   actualizarIdsArticulos(data: Articulo[]) {
      const url = `${base_url}/actualizarIdsArticulos`;
      console.log(url);

      return this.http.post(url, data, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, detalle: resp.detalle, ultimoId: resp.totalPaginas };
                  return respuesta;
               }
            )
         )
         ;
   }

   actualizarPrecioProducto(tiendaUrl: string, cK: string, cS: string, productId: number, precio: number) {
      //En principio no uso esta función.
      const auth = btoa(`${cK}:${cS}`);
      const headers = new HttpHeaders({
         'Content-Type': 'application/json',
         Authorization: `Basic ${auth}`,
      });

      // Actualiza el precio del producto usando el ID
      return this.http.put(
         `${tiendaUrl}/${productId}`,
         { regular_price: precio.toString() },
         { headers }
      );
   }

   actualizarPreciosEnBloque(tiendaUrl: string, cK: string, cS: string, productos: { idTiendaWeb: number; pvp: number }[]): Observable<any> {
      const auth = btoa(`${cK}:${cS}`);
      const headers = new HttpHeaders({
         Authorization: `Basic ${auth}`,
         'Content-Type': 'application/json',
      });
      const urlBatch: string = `${tiendaUrl}/batch`

      // Estructura de datos para la solicitud por lotes
      const body = {
         update: productos.map((producto) => ({
            id: producto.idTiendaWeb,
            regular_price: producto.pvp.toString(), // WooCommerce requiere el precio como string
         })),
      };

      // Realiza la solicitud por lotes
      // return this.http.post<any>(tiendaUrl, body, { headers });
      return this.http.post<any>(urlBatch, body, { headers }).pipe(
         catchError((error) => {
           console.error('Error al actualizar los precios:', error);
           return throwError(() => new Error('Error al actualizar los precios en WooCommerce.'));
         })
       );
   }
}
